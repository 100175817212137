import { useUser } from '@app/context/user';
import { useEffect } from 'react';

export const Scripts = () => {
  const { user } = useUser();

  useEffect(() => {
    const frontChat = document.getElementById('front-chat');
    const script = document.createElement('script');
    script.async = true;
    script.id = 'front-chat';

    if (!user || !user?.email) {
      script.dataset.anonymous = 'true';
      script.innerHTML = `window.FrontChat('init', {chatId: '3ed8002659cb2c6d498f8c0759ac89fe', useDefaultLauncher: true});`;
    }

    if (user && user?.email) {
      script.dataset.anonymous = 'false';
      script.innerHTML = `window.FrontChat('init', {chatId: '8bee36c4ca77cc8c1f1ebedc657e089d', email: '${user?.email}', userHash: '${user?.userHash}', name: '${user.firstName} ${user.lastName}' , useDefaultLauncher: true});`;
    }

    if (!frontChat) {
      document?.body?.appendChild(script);
    }
    if (frontChat) {
      frontChat.remove();
      document?.body?.appendChild(script);
    }
  }, [user]);

  return <div id="scripts" />;
};
