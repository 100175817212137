import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../Button';

export const RegisterNowBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-dorrus-background-secondary text-white p-12 pb-44 md:pb-12 rounded-2xl relative overflow-hidden md:min-h-[490px]">
      <div className="space-y-10 relative z-20 md:pl-10 w-full  md:w-1/2">
        <p className="text-[#548ECD] text-lg">Don't miss out—start earning today!</p>
        <h5 className="text-4xl md:text-5xl font-bold">
          Register now and earn
          <br /> 3X more points
        </h5>
        <p className="text-dorrus-text-secondary w-full md:w-[70%]">
          Within the first 3 months after signing up, enjoy the exclusive benefit of earning three times more points on
          all your bookings! Maximize your rewards and get closer to your next trip even faster.
        </p>
        <div className="flex gap-4 items-center">
          <Button onClick={() => navigate('/login')} kind="tertiary" className="text-dorrus-background-secondary">
            Register
          </Button>
          <Link
            to="/promoTC?promoCode=DORQ424SGP"
            className="text-sm text-dorrus-text-secondary underline underline-offset-2"
          >
            Bonus Terms
          </Link>
        </div>
      </div>
      <div className="absolute inset-0 h-full w-full right-0 flex items-end justify-end">
        <img className="hidden md:block h-full" src="/images/register-promo-image.png" />
        <img className="block md:hidden w-full" src="/images/register-promo-image-mobile.png" />
      </div>
    </div>
  );
};
